import plugins from './plugins'
import setting from './setting'
import products from './products'
import services from './services'
import treasury from './treasury'
import warehousing from './warehousing'
import accountancy from './accountancy'
import users from './users'
import characters from './characters'
import sales from './sales'
import purchases from './purchases'
import logs from './logs'
import activityLogs from "./activityLogs";
import shortcuts from "./shortcuts";

export default {
  // plugins page
  plugins,
  // setting page
  setting,
  // Products Pages
  products,
  // services Pages
  services,
  // Warehouse Pages
  warehousing,
  // Accountancy Pages
  accountancy,
  // Users Page
  users,
  //sales
  sales,
  //purchases
  purchases,
  // treasury
  treasury,
  characters,
  logs,
  shortcuts,

  activityLogs,

  // navMenuItems Words
  dashboard: 'داشبورد',

  beginning: 'اول دوره',
  beginningUsers: ' اولیه اشخاص',
  beginningCashs: 'اولیه صندوق',
  beginningBanks: 'اولیه بانک',
  beginningWarehouse: 'اولیه انبار',

  purchasesInvoice: 'فاکتور خرید',

  inventoryValuation: 'مرور مبلغی انبار',

  banks: 'بانک',
  cash: 'صندوق',
  transaction: 'تراکنش ها',
  transference: 'انتقال وجه داخلی',
  accountingTable: 'جدول حساب ها',
  income: 'درامد',
  cost: 'هزینه',

  accounting: 'حسابداری',

  save: 'ثبت',
  cancel: 'انصراف',

  logout: 'خروج',

  // alert title
  alert: {
    unsavedChanges: 'شما تغییراتی دارید که ذخیره نشده است, آیا مطمئنید که می خواهید خارج شوید؟',
    copy: 'متن کپی شد!',
    duplicateRequest: {
      title: 'هشدار',
      message: 'درخواست قبلی شما در حال پردازش است، لطفا منتظر بمانید'
    },
    error: {
      title: 'خطا',
      accessDenied: 'شما دسترسی به این فعالیت ندارید',
      openingBalanceUnlock: 'اول دوره باز است لطفا ابتدا اول دوره را قفل کنید',
      contactSupport: 'با پشتبانی تماس بگیرید',
      print: 'درخواست پرینت با خطا شد'
    },
    warning: {
      title: 'هشدار',
      repeatedRequestText: 'درخواست قبلی شما در حال پردازش است، لطفا منتظر بمانید'
    },
    message: {
      title: 'پیغام',
      pleaseWait: 'درخواست ارسال شد. لطفا منتظر بمانید...'
    }
  },

  // navbar actions
  navbar: {
    back: 'بازگشت',
    save: 'ذخیره',
    dynamicFinancialYear: 'سال مالی {start} تا {end}',

    notification: {
      newMessage: 'پیام جدید',
      showAll: 'مشاهده همه پیام ها'
    }
  },

  // table statics
  draggableTable: {

    filter: {
      all: 'همه',

      types: {
        search: 'شامل شود',
        equals: 'برابر باشد',
        notEqual: 'برابر نباشد',
        lessThanEquals: 'کوچکتر مساوی',
        greaterThanEquals: 'بزرگتر مساوی'
      }
    }
  },

  // login page
  login: {
    title: 'ورود',

    getPhoneNumberStepMessage1: 'لطفا شماره تلفن همراه خود را وارد کنید. رمز پیامکی ',
    getPhoneNumberStepMessage2: '(کد پنج رقمی)',
    getPhoneNumberStepMessage3: ' برای تایید شماره شما ارسال خواهد شد.',
    phoneNumber: 'شماره همراه',
    phoneNumberIsNotValid: 'شماره همراه به صورت صحیح وارد نشده است',
    phoneNumberIsNull: 'شماره همراه وارد نشده است',

    otpCodeSentStepMessage1: 'برای ',
    otpCodeSentStepMessage2: ' یک کد ۵ رقمی ارسال شد. لطفا کد را وارد کنید',
    otpCodeIsNotValid: 'کد اعتبارسنجی به صورت صحیح وارد نشده است',
    otpCodeIsNull: 'کد اعتبارسنجی وارد نشده است',
    retrieveTheCode: 'دریافت مجدد کد',

    loginToPanel: 'ورود به پنل {name}',
    resendOTPMessage: 'برای دریافت مجدد کد یکی از روش های زیر را انتخاب کنید',
    otpCall: 'تا لحظاتی دیگر تماس با شماره {number} برقرار خواهد شد',
    resendOTPTypes: {
      resend: 'ارسال مجدد',
      sms: 'پیامک',
      tel: 'تماس'
    },

    otp: 'یکبار رمز',
    otpSend: 'کد اعتبارسنجی به {number} ارسال شد',

    getSuperAdminTokenStepMessage: 'لطفا توکن خود را وارد کنید.',
    token: 'توکن مدیر سامانه',

    buttons: {
      getOTPCode: 'دریافت کد',
      login: 'ورود'
    },

    notification: {
      userNotFound: 'کاربری با این شماره پیدا نشد!',
      accessError: 'دسترسی شما به سامانه مسدود شده است!',

      login: {
        title: 'پیغام',
        message: 'شما با موفقیت وارد سامانه شدید'
      },

      logout: {
        title: 'پیغام',
        message: 'شما با موفقیت از سامانه خارج شدید'
      },

      forceLogout: {
        title: 'پیغام',
        message: 'به علت عدم استفاده طولانی مدت از سامانه ,دوباره وارد شوید'
      }
    }

  },

  // profile page
  profile: {
    title: 'پروفایل',
    dynamicTitle: 'پروفایل {name}',

    details: {
      dynamicCompany: 'شرکت {company}'
    },

    importantData: {
      balance: 'کیف پول',
      score: 'امتیاز',
      character: 'گروه کاربری'
    },

    actions: {
      transactions: 'گردش مالی',
      addresses: 'آدرس ها',
      activeSessions: 'نشست های فعال',
      receivedEvents: 'پیام های دریافتی',
      sentEvents: 'پیام های ارسالی',
      customize: 'شخصی سازی سامانه',
    },

    notifications: {
      edit: {
        success: 'اطلاعات با موفقیت به روز شد',
        error: 'به روز رسانی اطلاعات با خطا همراه شد'
      },

      revokeSession: {
        success: 'نشست مورد نظر با موفقیت لغو شد',
        error: 'لغو نشست مورد نظر با خطا همراه شد'
      },

      parseError: {
        required: 'مقادیر الزامی وارد نشده است'
      }
    },

    edit: {
      title: 'ویرایش اطلاعات پروفایل',

      labels: {
        gender: 'جنسیت',
        base: 'پایه',

        company: {
          name: 'نام شرکت',
          ownerName: 'نام و نام خانوادگی مسئول خرید',
          phoneNumber: 'شماره تماس مسئول خرید',
          email: 'ایمیل شرکت'
        },

        user: {
          name: 'نام',
          family: 'نام خانوادگی',
          phoneNumber: 'شماره تماس',
          email: 'ایمیل',
          telegramToken: 'توکن تلگرام'
        }
      },

      validators: {
        name: 'نام به صورت صحیح وارد نشده است',
        family: 'نام خانوادگی به صورت صحیح وارد نشده است',
        companyName: 'نام شرکت نمی تواند خالی باشد',
        phoneNumber: 'فرمت شماره تماس اشتباه است',
        email: 'فرمت ایمیل اشتباه است'
      }
    },

    address: {
      title: 'آدرس ها',

      labels: {
        name: 'نام آدرس',
        country: 'کشور',
        province: 'استان',
        city: 'شهر',
        address: 'نشانی پستی',
        plaque: 'پلاک',
        postalCode: 'کدپستی',
        delete: 'حذف',
        save: 'ثبت',

        receiver: {
          info: 'اطلاعات گیرنده',
          name: 'نام گیرنده',
          family: 'نام خانوادگی گیرنده',
          phoneNumber: 'شماره تماس گیرنده'
        }
      },

      description: {
        noAddress: 'هیچ آدرسی ثبت نشده است!'
      },

      validators: {
        city: 'شهر نمی تواند انتخاب نشود',
        province: 'استان نمی تواند انتخاب نشود',
        name: 'نام به صورت صحیح وارد نشده است',
        nameCantEmpty: 'نام نمی تواند خالی باشد',
        address: 'نشانی به صورت صحیح وارد نشده است',
        addressCantEmpty: 'نشانی نمی تواند خالی باشد',
        plaque: 'فرمت پلاک اشتباه است',
        postalCode: 'فرمت کدپستی اشتباه است',
        phoneNumber: 'فرمت شماره تماس اشتباه است',
        receiver: {
          name: 'نام به صورت صحیح وارد نشده است',
          nameCantEmpty: 'نام گیرنده نمی تواند خالی باشد',
          family: 'نام خانوادگی به صورت صحیح وارد نشده است',
          familyCantEmpty: 'نام خانوادگی گیرنده نمی تواند خالی باشد',
          phoneNumber: 'شماره تماس گیرنده نمی تواند خالی باشد'
        }
      },

      notifications: {
        insert: {
          success: 'آدرس جدید با موفقیت ایجاد شد',
          error: 'افزودن آدرس جدید با خطا همراه شد'
        },
        edit: {
          success: 'ویرایش آدرس با موفقیت انجام شد',
          error: 'ویرایش آدرس با خطا همراه شد'
        },
        delete: {
          success: 'آدرس با موفقیت حذف شد',
          error: 'حذف آدرس با خطا همراه شد'
        }
      },

      insert: {
        title: 'افزودن آدرس جدید'
      },

      edit: {
        title: 'ویرایش آدرس'
      }
    },

    events: {
      seenTypes: {
        all: 'همه',
        seen: 'مشاهده شده',
        unSeen: 'مشاهده نشده'
      },

      list: {
        table: {
          header: {
            rowNumber: 'ردیف',
            creator: 'ارسال کننده',
            receiver: 'دریافت کننده',
            subject: 'موضوع',
            content: 'محتوا',
            seen: 'وضعیت مشاهده',
            type: 'نوع',
            priority: 'اولویت',
            createdAt: 'تاریخ ارسال'
          }
        }
      },

      receive: {
        title: 'پیام های دریافتی'
      },

      send: {
        title: 'پیام های ارسالی'
      }
    },

    transactions: {
      title: 'گردش مالی',

      table: {
        header: {
          docNumber: 'شماره سند',
          date: 'تایخ',
          documentation: 'مستندات',
          price: 'مبلغ',
          balance: 'موجودی'
        }
      }
    },

    activeSessions: {
      title: 'نشست های فعال من',

      labels: {
        maxSessionHelper: 'شما حداکثر میتوانید {num} نشست فعال داشته باشید!',
        signIn: 'تاریخ ورود {date}',
        lastUsed: 'آخرین دسترسی در {date}',
        currentSession: 'آنلاین',
        device: '{browser} در {device}',
        revoke: 'لغو'
      },

      confirmations: {
        revoke: {
          title: 'تاییدیه لغو نشست فعال',
          body: 'آقا از حذف نشست فعال خود با آی پی {ip} اطمینان دارید؟'
        }
      }
    },

    buttons: {
      save: 'ذخیره تغییرات',
      reset: 'برگرداندن تغییرات',
      removeAvatar: 'حذف عکس',
      uploadAvatar: 'بارگزاری عکس'
    },

    General: {
      title: 'عمومی',
      avatar: {
        rule: 'فرمت های مجاز JPG, JPEG یا PNG. حداکثر حجم عکس 800 کیلوبایت'
      },
      user: {
        name: 'نام',
        lastName: 'نام خانوادگی',
        role: 'مسئولیت',
        phoneNumber: 'شماره تماس',
        address: 'آدرس',
        maxDebt: 'حداکثر میزان بدهی',
        balance: 'میزان اعتبار'
      }
    },

    MyFinancialReports: {
      title: 'گزارشات مالی من'
    },

    customize: {
      title: 'شخصی سازی سامانه',

      labels: {
        theme: 'انتخاب تم مورد نظر'
      }
    },
  },

  smsPanel: 'پنل اس ام اس',

  // opening inventories
  opening: {
    users: {
      title: 'اول دوره اشخاص'

    },

    cashBoxes: {
      title: 'اول دوره صندوق ها'

    },

    banks: {
      title: 'اول دوره بانک ها'

    },

    stores: {
      title: 'اول دوره انبار ها'

    }
  },

  // companies
  companies: {
    title: 'لیست شرکت ها',

    table: {
      header: {
        row: 'ردیف',
        code: 'کد',
        name: 'نام شرکت',
        equity: 'سرمایه',
        totalDebt: 'بدهی کل',
        totalAsset: 'دارایی کل'
      }
    },

    labels: {
      generalInfo: 'اطلاعات عمومی شرکت',
      telInfo: 'اطلاعات تماس شرکت',
      financialInfo: 'اطلاعات مالی',
      uploadLogo: 'آپلود لوگو',
      preview: 'پیشنمایش:',
      code: 'کد شرکت',
      name: 'نام شرکت',
      managerName: 'نام و نام خانوادگی مدیر',
      address: 'آدرس',
      phoneNumber: 'شماره تماس',
      managerPhoneNumber: 'شماره تماس مدیر',
      financialFrom: 'شروع سال مالی',
      financialTo: 'انتهای سال مالی'
    },

    description: {
      logoRule: 'فرمت های مجاز JPG, JPEG یا PNG. حداکثر حجم لوگو 800 کیلوبایت',
      generalInfo: 'در این باکس اطلاعات عمومی شرکت نمایش داده می شود.',
      telInfo: 'در این باکس اطلاعات تماس شرکت نمایش داده می شود.',
      financialInfo: 'در این باکس اطلاعات مالی شرکت نمایش داده می شود.'
    },

    buttons: {
      save: 'ذخیره تغییرات',
      reset: 'برگرداندن تغییرات',
      removeLogo: 'حذف لوگو',
      uploadLogo: 'بارگزاری لوگو'
    },

    validators: {
      imageSize: 'حجم فایل انتخاب شده بیش از حد مجاز است',
      fileFormat: 'فرمت فایل انتخاب شده مجاز نیست',
      name: 'نام وارد شده معتبر نمیباشد'
    },

    notifications: {
      logo: {
        upload: {
          success: 'لوگو با موفقیت آپلود شد'
        },
        delete: {
          success: 'لوگو با موفقیت حذف شد'
        }
      },

      insert: {
        success: 'اطلاعات فروشگاه با موفقیت اضافه شد'
      },

      edit: {
        success: 'اطلاعات فروشگاه با موفقیت بروز شد'
      }
    },

    company: {
      title: 'شرکت'
    },

    insert: {
      title: 'افزودن شرکت'
    },

    edit: {
      title: 'ویرایش اطلاعات شرکت'
    }
  },

  // backup page
  backup: {
    title: 'پشتیبان گیری',

    status: {
      all: 'همه',
      saving: 'در حال ذخیره',
      saved: 'ذخیره شده',
      retrieved: 'بازیابی شده'
    },

    notifications: {
      insert: {
        success: 'فایل پشتیبان با موفقیت ایجاد شد',
        error: 'ایجاد فایل پشتیبان با خطا مواجه شد'
      },

      recovery: {
        success: 'درخواست با موفقیت ارسال شد',
        error: 'این پشتیبان قابل بازیابی نمی باشد',
        cantSend: 'ارسال درخواست با خطا مواجه شد'
      }
    },

    confirmations: {
      insert: {
        title: 'ایجاد پشتیبان',
        body: 'آیا از ایجاد پشتیبان اطمینان دارید؟'
      },

      recovery: {
        title: 'ایجاد پشتیبان',
        body: 'آیا از بازیابی پشتیبان اطمینان دارید؟'
      }
    },

    list: {
      table: {
        header: {
          rowNumber: 'ردیف',
          date: 'تاریخ',
          status: 'وضعیت',
          recovery: 'بازیابی'
        }
      }
    }
  },

  // categories pages
  categories: {
    title: 'دسته بندی ها',

    list: {
      title: 'دسته بندی ها'
    },

    insert: {
      title: 'افزودن دسته بندی جدید',

      notifications: {
        insert: {
          success: 'دسته بندی با موفقیت ایجاد شد',
          error: 'افزودن دسته بندی با خطا همراه شد'
        }
      }
    },

    edit: {
      title: 'ویرایش دسته بندی',

      notifications: {
        edit: {
          success: 'دسته بندی با موفقیت ویرایش شد',
          error: 'ویرایش دسته بندی با خطا همراه شد'
        }
      }
    },

    delete: {
      title: '',

      notifications: {
        delete: {
          success: 'دسته بندی با موفقیت حذف شد',
          error: 'حذف دسته بندی با خطا همراه شد'
        }
      }
    },

    labels: {
      none: 'هیچ کدام',
      name: 'عنوان دسته بندی',
      parent: 'دسته بندی والد',
      insertAttribute: 'افزودن ویژگی جدید',
      editAttribute: 'ویرایش ویژگی جدید',
      chooseAttribute: 'انتخاب ویژگی جدید',
      suggestAttributes: 'ویژگی های پیشنهادی',
      withoutCategory: 'بدون دسته بندی',
      productCount: '{count} محصول'
    },

    validators: {
      attributeExist: 'ویژگی { name } در این دسته بندی وجود دارد',
      noChange: 'هیچ تغییری اعمال نشده است'
    },

    confirmations: {
      delete: {
        title: 'حذف دسته بندی',
        body: 'در صورت حذف این دسته بندی، دسته بندی کلیه محصولات این دسته بندی حذف خواهد شد. آیا از حذف  {name} اطمینان دارید؟'
      }
    }
  },

  // attributes pages
  attributes: {
    title: 'ویژگی ها',

    list: {
      title: 'لیست ویژگی ها',

      table: {
        header: {
          rowNumber: 'ردیف',
          name: 'نام ویژگی',
          values: 'مقادیر ویژگی',
          categories: 'دسته بندی های ویژگی',
          productCount: 'تعداد محصولات'
        }
      }
    },

    insert: {
      title: 'افزودن ویژگی جدید',

      notifications: {
        insert: {
          success: 'ویژگی جدید با موفقیت ثبت شد',
          error: 'ثبت ویژگی جدید با خطا همراه شد'
        },
        insertValue: {
          success: 'مقدار جدید با موفقیت ذخیره شد',
          error: 'ذخیره شدن مقدار جدید با خطا همراه شد'
        }
      }
    },

    edit: {
      title: 'ویرایش ویژگی',

      notifications: {
        edit: {
          success: 'ویژگی با موفقیت ویرایش شد',
          error: 'ویرایش ویژگی با خطا همراه شد'
        },
        editValue: {
          success: 'مقدار ویژگی با موفقیت بروزرسانی شد',
          error: 'بروزرسانی شدن مقدار جدید با خطا همراه شد'
        }
      }
    },

    profile: {
      title: 'پروفایل ویژگی'
    },

    setProperties: {
      title: 'اولویت بندی ویژگی ها',

      statusTable: {
        loading: 'در حال بارگذاری ...',
        error: 'خطا در دریافت اطلاعات'
      }
    },

    values: {
      table: {
        header: {
          row: 'ردیف',
          name: 'عنوان',
          slug: 'نامک',
          update: 'ویرایش',
          delete: 'حذف',
          products: 'کالاها'
        }
      }
    },

    delete: {
      notifications: {
        delete: {
          success: 'ویژگی مورد نظر با موفقیت حذف شد',
          error: 'حذف ویژگی با خطا همراه شد'
        },
        deleteValue: {
          success: 'مقدار ویژگی مورد نظر با موفقیت حذف شد',
          error: 'حذف مقدار ویژگی با خطا همراه شد'
        }
      },

      validators: {
        delete: 'ویژگی مورد نظر قابل حذف نمی باشد'
      }
    },

    select: {
      table: {
        header: {
          select: 'انتخاب',
          name: 'نام ویژگی',
          values: 'مقادیر ویژگی'
        }
      }
    },

    labels: {
      name: 'عنوان',
      slug: 'نامک',
      archive: 'بایگانی فعال شود؟',
      value: 'مقدار ویژگی',
      values: 'مقادیر:',
      insertValue: 'افزودن مقدار جدید',
      editValue: 'ویرایش مقدار',
      categories: 'دسته بندی ها:',
      shops: 'سایت ها:',
      delete: 'حذف ویژگی',
      withoutCategory: 'بدون دسته بندی'
    },

    validators: {
      name: 'نام ویژگی نمی تواند بیش از {num} کاراکتر باشد',
      slug: 'نامک ویژگی نمی تواند بیش از {num} کاراکتر باشد',
      value: 'مقدار ویزگی نمی تواند بیش از {num} کاراکتر باشد',
      valueSlug: 'مقدار نامک ویزگی نمی تواند بیش از {num} کاراکتر باشد',
      nameRequired: 'فیلد عنوان اجباری است',
      valueRequired: 'فیلد مقدار ویژگی اجباری است',
      slugRequired: 'فیلد نامک اجباری است',
      noChange: 'هیچ تغییری اعمال نشده است'
    },

    notifications: {
      setProperties: {
        success: 'اولویت بندی ویژگی ها با موفقیت انجام شد',
        error: 'اولویت بندی ویژگی ها با با خطا همراه شد'
      },

      parseError: {
        slug: 'نامک وارد شده قبلا استفاده شده است'
      }
    },

    confirmations: {
      delete: {
        title: 'حذف ویژگی',
        body: 'آیا از حذف ویزگی  {name} اطمینان دارید؟'
      },
      deleteValue: {
        title: 'حذف مقدار ویژگی',
        body: 'آیا از حذف مقدار ویزگی  {name} اطمینان دارید؟'
      }
    }
  },

  // documents page
  documents: {
    title: 'اسناد',

    document: {
      title: 'سند',

      table: {

        header: {
          row: 'ردیف',
          docNumber: 'شماره سند',
          date: 'تاریخ',
          reference: 'مرجع',
          details: 'تفصیل',
          price: 'مبلغ',
          type: 'نوع'
        }
      }
    }
  },

  //invoice types
  invoice_types: {
    1: 'فاکتور فروش',
    2: 'فاکتور خرید',
    3: 'فاکتور مرجوع فروش',
    4: 'فاکتور مرجوع خرید'
  },

  // reports
  reports: {
    title: 'گزارشات',

    logs: {
      title: 'لاگ ها',

      table: {
        header: {
          rowNumber: 'ردیف',
          createdAt: 'تاریخ لاگ',
          userName: 'کاربر',
          description: 'توضیحات',
          details: 'جزییات',
          type: 'نوع فعالیت'
        }
      },

      dialogs: {
        title: 'جزییات لاگ',
        withoutDescription: 'بدون توضیحات بیشتر'
      },

      list: {
        title: 'لاگ ها'
      }
    },

    usersTotalSales: {
      title: 'مجموع فروش به اشخاص',

      table: {
        header: {
          row: 'ردیف',
          name: 'نام و نام خانوادگی',
          company: 'شرکت',
          phoneNumber: 'تلفن همراه',
          totalSales: 'مجموع فروش',
          date: 'تاریخ'
        }
      }
    },

    sale: {
      title: 'فروش'
    },

    charts: {
      main: {
        labels: {
          selectChart: 'ویرایش نمودارهای فعال',
          today: 'امروز',
          week: 'هفته جاری',
          month: 'ماه جاری',
          year: 'سال جاری',
          sale: 'فروش',
          totalSale: 'فروش کل',
          personalSale: 'حضوری',
          telephoneSale: 'تلفنی',
          onlineSale: 'اینترنتی',
          grossProfit: 'سود فروش',
          receive: 'دریافت',
          payment: 'پرداخت',
          cost: 'هزینه',
          creditor: 'بستانکاری',
          debtor: 'بدهکاری',
          default: 'پیش فرض',
          price: 'قیمت ({currency})'
        },

        notifications: {
          chartNotSelected: 'حداقل یک نمودار باید انتخاب شود'
        }
      },
      assets: {
        title: 'موجودی نقد و بانک',
        labels: {
          treasury: 'خزانه داری',
          banks: 'بانک',
          cashes: 'صندوق',
          payment_gateways: 'درگاه پرداخت'
        }
      },
      attributeAssets: {
        title: 'فروش ویژگی ها',

        labels: {
          dateRange: 'از {start} تا {end}',
          today: 'امروز',
          yesterday: 'دیروز',
          week: 'هفته قبل',
          month: 'ماه قبل',
          selectDate: 'انتخاب تاریخ',
          price: 'مبلغ ({currency})',
          category: 'دسته بندی',
          withoutCategory: 'بدون دسته بندی',
          simpleProduct: 'کالای ساده',
          saleProfit: 'سود',
          totalSale: 'فروش',
          totalProfit: 'سود کل',
          totalPrice: 'مبلغ کل',
        },

        notifications: {
          parseError: {
            rangeLength: 'بازه انتخاب شده کمتراز یک سال باشد',
            endRange: 'بازه انتهایی انتخاب شده معتبر نیست'
          }
        }
      }
    },

    tops: {
      products: {
        title: 'برترین محصولات',

        table: {
          header: {
            row: 'ردیف',
            name: 'نام محصول',
            quantity: 'تعداد',
            score: 'امتیاز',
            saleInventory: 'موجودی قابل فروش',
            show: 'مشاهده'
          }
        }
      },

      customer: {
        title: 'برترین مشتریان',

        labels: {
          profile: 'پروفایل',
          lastWeek: 'هفته قبل',
          lastMonth: 'ماه قبل',
          lastYear: 'سال قبل'
        },

        table: {
          header: {
            row: 'ردیف',
            name: 'نام و نام خانوادگی',
            phoneNumber: 'شماره تماس',
            price: 'مبلغ',
            score: 'امتیاز',
            show: 'مشاهده'
          }
        }
      },

      invoice: {
        title: 'آخرین فاکتورهای فروش امروز',

        table: {
          header: {
            row: 'ردیف',
            invoiceNumber: 'شماره فاکتور',
            name: 'نام مشتری',
            status: 'وضعیت',
            show: 'مشاهده'
          }
        }
      }
    }
  },

  // tickets
  tickets: {
    title: 'تیکت ها',

    support: {
      title: 'تیکت پشتیبانی',

      statusTypes: {
        all: 'همه',
        created: 'ایجاد شده',
        customerAnswer: 'پاسخ مشتری',
        operatorAnswer: 'پاسخ اپراتور',
        pending: 'در انتظار بررسی',
        completed: 'بسته شده',
        canceled: 'کنسل شده'
      },

      sendStatusTypes: {
        send: 'ارسال شده',
        notSend: 'ارسال نشده'
      },

      seenStatusTypes: {
        seen: 'مشاهده شده',
        notSeen: 'مشاهده نشده'
      },

      labels: {
        all: 'همه',
        selectType: 'موضوع رو انتخاب کنید',
        service: 'سرویس',
        subject: 'موضوع تیکت',
        message: 'پیام',
        dynamicAttach: 'پیوست {number}',
        insertAttach: 'پیوست جدید',
        unknown: 'نامشخص',
        operator: 'اپراتور'
      },

      placeholder: {
        subject: 'موضوع تیکت خود را وارد کنید',
        message: 'متن پیام خود را وارد کنید'
      },

      table: {
        header: {
          row: 'ردیف',
          department: 'دپارتمان',
          service: 'نام سامانه',
          subject: 'موضوع',
          date: 'تاریخ ایجاد',
          lastUpdate: 'آخرین به روز رسانی',
          operatorName: 'اپراتور',
          creator: 'ایجاد کننده',
          status: 'وضعیت',
          sendStatus: 'وضعیت ارسال',
          seen: 'مشاهده شده'
        }
      },

      notifications: {
        attachUpload: 'فایل مورد نظر آپلود شد',
        requestSend: 'درخواست شما ارسال شد لطفا منتظر بمانید!',

        insert: {
          success: 'تیکت مورد نظر با موفقیت ایجاد شد',
          error: 'افزودن تیکت با خطا همراه شد'
        },

        sendMessage: {
          success: 'پیام مورد نظر با موفقیت ارسال شد',
          error: 'ارسال پیام مورد نظر با خطا همراه شد'
        },

        changeOperator: {
          success: 'ویرایش اپراتور تیکت با موفقیت انجام شد',
          error: 'ویرایش اپراتور تیکت با خطا همراه شد'
        },

        parseError: {
          service: 'سرویس انتخاب شده معتبر نیست!',
          subject: 'موضوع انتخاب شده معتبر نیست!',
          message: 'متن وارد شده معتبر نیست!',
          attachments: 'پیوست انتخاب شده معتبر نیست!',
          operator: 'اپراتور انتخاب شده معتبر نیست!'
        }
      },

      confirmations: {
        send: {
          title: 'تاییدیه ارسال پیام',
          body: 'آیا از ارسال پیام خود اطمینان دارید؟'
        }
      },

      validators: {
        subject: 'موضوع تیکت به صورت صحیح وارد نشده است!',
        message: 'متن پیام به صورت صحیح وارد نشده است!',
        service: 'سرویس به صورت صحیح انتخاب نشده است!'
      },

      list: {
        title: 'تیکت های پشتیبانی'
      },

      insert: {
        title: 'افزودن تیکت پشتیبانی جدید'
      },

      answer: {
        title: 'افزودن پاسخ به تیکت پشتیبانی'
      },

      detail: {
        title: 'جزئیات تیکت پشتیبانی',

        labels: {
          ticketId: 'تیکت ',
          status: 'وضعیت تیکت: ',
          createdAt: 'زمان ایجاد: ',
          lastUpdate: 'آخرین بروزرسانی: ',
          user: 'کاربر',
          operator: 'اپراتور'
        }
      }
    },

    organization: {
      title: 'تیکت درون سازمانی',

      statusTypes: {
        all: 'همه',
        created: 'ایجاد شده',
        awaitingReview: 'در انتظار بررسی',
        answered: 'پاسخ داده شد',
        pending: 'در حال بررسی',
        completed: 'تکمیل شده',
        canceled: 'کنسل شده'
      },

      sendStatusTypes: {
        send: 'ارسال شده',
        notSend: 'ارسال نشده'
      },

      seenStatusTypes: {
        seen: 'مشاهده شده',
        notSeen: 'مشاهده نشده'
      },

      labels: {
        all: 'همه',
        selectType: 'موضوع رو انتخاب کنید',
        user: 'گیرنده',
        subject: 'موضوع تیکت',
        message: 'پیام',
        dynamicAttach: 'پیوست {number}',
        insertAttach: 'پیوست جدید',
        unknown: 'نامشخص',
        operator: 'اپراتور'
      },

      placeholder: {
        subject: 'موضوع تیکت خود را وارد کنید',
        message: 'متن پیام خود را وارد کنید'
      },

      table: {
        header: {
          row: 'ردیف',
          creator: 'ایجاد کننده',
          user: 'مسئول پیگیری',
          subject: 'موضوع',
          date: 'تاریخ ایجاد',
          lastUpdate: 'آخرین به روز رسانی',
          status: 'وضعیت'
        }
      },

      notifications: {
        attachUpload: 'فایل مورد نظر آپلود شد',
        requestSend: 'درخواست شما ارسال شد لطفا منتظر بمانید!',

        insert: {
          success: 'تیکت مورد نظر با موفقیت ایجاد شد',
          error: 'افزودن تیکت با خطا همراه شد'
        },

        sendMessage: {
          success: 'پیام مورد نظر با موفقیت ارسال شد',
          error: 'ارسال پیام مورد نظر با خطا همراه شد'
        },

        complete: {
          success: 'تیکت مورد نظر با موفقیت تکمیل شد',
          error: 'تکمیل تیکت مورد نظر با خطا همراه شد'
        },

        changeOperator: {
          success: 'ویرایش اپراتور تیکت با موفقیت انجام شد',
          error: 'ویرایش اپراتور تیکت با خطا همراه شد'
        },

        parseError: {
          user: 'گیرنده انتخاب شده معتبر نیست!',
          service: 'سرویس انتخاب شده معتبر نیست!',
          subject: 'موضوع انتخاب شده معتبر نیست!',
          message: 'متن وارد شده معتبر نیست!',
          attachments: 'پیوست انتخاب شده معتبر نیست!',
          operator: 'اپراتور انتخاب شده معتبر نیست!'
        }
      },

      confirmations: {
        send: {
          title: 'تاییدیه ارسال پیام',
          body: 'آیا از ارسال پیام خود اطمینان دارید؟'
        }
      },

      validators: {
        user: 'گیرنده تیکت به صورت صحیح وارد نشده است!',
        subject: 'موضوع تیکت به صورت صحیح وارد نشده است!',
        message: 'متن پیام به صورت صحیح وارد نشده است!',
        service: 'سرویس به صورت صحیح انتخاب نشده است!'
      },

      list: {
        title: 'تیکت های درون سازمانی'
      },

      insert: {
        title: 'افزودن تیکت درون سازمانی جدید'
      },

      answer: {
        title: 'افزودن پاسخ به تیکت درون سازمانی'
      },

      detail: {
        title: 'جزئیات تیکت درون سازمانی',

        labels: {
          ticketId: 'تیکت ',
          status: 'وضعیت تیکت: ',
          createdAt: 'زمان ایجاد: ',
          lastUpdate: 'آخرین بروزرسانی: ',
          user: 'کاربر',
          operator: 'اپراتور',
          setComplete: 'مشکلم حل شد'
        }
      }
    }
  },

  // events
  events: {
    title: 'اطلاعیه ها',

    labels: {
      priority: 'اولویت',
      type: 'موضوع',
      subject: 'موضوع',
      content: 'متن',
      bySystem: 'سیستمی'
    },

    eventTypes: {
      all: 'همه',
      update: 'بروزرسانی سامانه',
      activity: 'فعالیت',
      message: 'پیام',
      warning: 'هشدار'
    },

    priority: {
      all: 'همه',
      veryLow: 'خیلی کم',
      low: 'کم',
      medium: 'متوسط',
      high: 'زیاد',
      veryHigh: 'خیلی زیاد'
    },

    validators: {
      subject: 'موضوع به صورت صحیح وارد نشده است',
      content: 'محتوا به صورت صحیح وارد نشده است'
    },

    notifications: {
      insert: {
        success: 'پیام با موفقیت ارسال شد',
        error: 'ارسال پیام با خطا همراه شد'
      },

      parseError: {
        type: 'نوع انتخاب نشده است',
        priority: 'اولویت انتخاب نشده است'
      }
    },

    confirmations: {
      seenAll: {
        title: 'تاییدیه مشاهده همه پیام ها',
        body: 'شما {count} پیام خوانده نشده دارید، آیا از مشاهده شدن همه پیام ها اطمینان دارید؟'
      }
    },

    list: {
      table: {
        header: {
          rowNumber: 'ردیف',
          sender: 'ارسال کننده',
          subject: 'موضوع',
          content: 'محتوا',
          type: 'نوع',
          priority: 'اولویت',
          sendDate: 'تاریخ ارسال'
        }
      }
    },

    insert: {
      title: 'ارسال اطلاعیه جدید'
    },

    event: {
      labels: {
        understand: 'متوجه شدم',
        checkController: 'چک کردن کنترلر'
      }
    }
  },

  // draggable dynamic table
  draggableDynamicTable: {

    filters: 'فیلترها',

    activeFilters: {
      title: 'فیلترهای اعمال شده'
    },

    setting: {
      showColumns: 'نمایش ستون ها'
    },

    notifications: {
      loading: 'در حال بارگزاری اطلاعات ...',
      error: 'دریافت اطلاعات با خطا مواجه شد',
      empty: 'هیچ اطلاعاتی وجود ندارد'
    },

    actions: {
      print: 'پرینت',
      download: 'دریافت',
      setting: 'تنظیمات جدول'
    }
  },

  // accessTreeCheckBox
  accessTreeCheckBox: {
    labels: {
      chooseCategory: 'انتخاب دسترسی'
    }
  },

  // custom dialog
  customDialog: {
    confirm: 'تایید',
    cancel: 'انصراف'
  },

  // custom file input
  customFileInput: {
    uploaded: 'عکس شما آپلود شد',
    sizeError: 'حجم فایل باید کمتر از {size} کیلوبایت باشد!'
  },

  // customProfileImageInput
  customProfileImageInput: {
    validators: {
      imageSize: 'حجم عکس باید کمتر از {size} کیلوبایت باشد!'
    }
  },

  // custom date picker input
  customDatePickerInput: {
    invalidText: 'فیلد وارد شده معتبر نمی باشد'
  },

  // contextMenu
  contextMenu: {
    actions: {
      openNewTab: 'بازکردن در تب جدید',
      copyLink: 'کپی آدرس لینک',
      copy: 'کپی',
      paste: 'چسباندن'
    },
    notifications: {
      clipboard: 'متن مورد نظر با موفقیت کپی شد'
    }
  },

  // date picker
  datePicker: {
    labels: {
      submit: 'تائید',
      cancel: 'انصراف',
      now: 'اکنون',
      nextMonth: 'ماه بعد',
      prevMonth: 'ماه قبل'
    },

    formats: {
      date: 'jYYYY/jMM/jDD',
      dateTime: 'jYYYY/jMM/jDD HH:mm',
      time: 'HH:mm'
    }
  },

  smsMessageInput: {
    title: 'ارسال پیام',

    labels: {
      receiverCount: 'تعداد دریافت کنندگان',
      message: 'متن پیام'
    },

    notifications: {
      send: {
        success: 'پیام با موفقیت ارسال شد',
        error: 'ارسال پیام با خطا همراه شد'
      }
    },

    validators: {
      invalidMessage: 'متن پیام معتبر نیست',
      receiverCount: 'دریافت کننده ای برای پیام وجود ندارد'
    }
  },

  updateInvoiceProgressbar: {
    title: 'نوار وضعیت بروزرسانی فاکتور ها',

    labels: {
      seeMore: 'مشاهده جزئیات',
      sale_invoice: 'فاکتور فروش شماره {id} ساخته شد',
      return_sale_invoice: 'فاکتور مرجوع فروش شماره {id} ساخته شد',
      purchase_invoice: 'فاکتور خرید شماره {id} ساخته شد',
      return_purchase_invoice: 'فاکتور مرجوع خرید شماره {id} ساخته شد',
      transfer: 'انتقالی انبار شماره {id} ساخته شد',
      storeroom_conversion: 'حواله تبدیلات شماره {id} ساخته شد',
      storeroom_deficit: 'کسورات انبار شماره {id} ساخته شد',
      storeroom_surplus: 'اضافات انبار شماره {id} ساخته شد'
    }
  },

  // Button
  create: 'افزودن',

  // mixins
  documentDescriptions: {
    receive: {
      description: 'سند دریافت وجه شماره {id}',
      dynamicDescription: 'سند دریافت وجه | {description}'
    },
    payment: {
      description: 'سند پرداخت وجه شماره {id}',
      dynamicDescription: 'سند پرداخت وجه | {description}'
    },
    cost: {
      description: 'سند هزینه شماره {id}',
      dynamicDescription: 'سند هزینه | {description}'
    },
    beginningInventory: {
      description: 'سند اول دوره'
    },
    internalFundTransfer: {
      description: 'برداشت از {payer} واریز به {payee}'
    },
    receivePaymentGateway: {
      description: 'اعلام وصول از {payer} واریز به {payee}'
    },
    sale: {
      description: 'فاکتور فروش شماره {id}'
    },
    returnSale: {
      description: 'فاکتور مرجوع فروش  شماره{id}'
    },
    purchase: {
      description: 'فاکتور خرید شماره {id}'
    },
    returnPurchase: {
      description: 'فاکتور مرجوع خرید شماره {id}'
    },
    warehouse_receipt: {
      on_way_description: 'حواله رسید انبار ذیل {invoice_type} شماره {invoice_id}',
      description: 'حواله رسید انبار شماره {receipt_id}'
    },
    inventory_transfer: {
      description: 'حواله انتقالی انبار شماره {receipt_id}'
    },
    warehouse_requisition: {
      waiting_to_send_description: 'حواله در انتظار ارسال انبار ذیل {invoice_type} شماره {invoice_id}',
      description: 'حواله خروج انبار شماره {receipt_id}',
      description_dynamic: 'حواله خروج انبار {receipt_id} ذیل {invoice_type} شماره {invoice_id}'
    },
    inventory_deficit: {
      description: 'فاکتور کسورات انبار شماره {id}'
    },
    storeroom_surplus: {
      description: 'فاکتور اضافات انبار شماره {id}'
    },
    storeroom_conversion: {
      description: 'فاکتور تبدیلات انبار شماره {id}'
    },
    sale_cooperation: {
      description: 'همکاری در فروش ذیل {invoice_type} شماره {invoice_id}'
    },
    purchase_adjustment: {
      description: 'سند ابطال فاکتور خرید شماره {id}'
    },
    return_purchase_adjustment: {
      description: 'سند ابطال فاکتور مرجوع خرید شماره {id}'
    },
    sale_adjustment: {
      description: 'سند ابطال فاکتور فروش شماره {id}'
    },
    sale_cooperation_adjustment: {
      description: 'سند ابطال همکاری در فروش شماره {id}'
    },
    return_sale_adjustment: {
      description: 'سند ابطال فاکتور مرجوع فروش شماره {id}'
    },
    receive_adjustment: {
      description: 'سند ابطال فاکتور دریافت وجه شماره {id}'
    },
    payment_adjustment: {
      description: 'سند ابطال فاکتور پرداخت وجه شماره {id}'
    },
    cost_adjustment: {
      description: 'سند ابطال فاکتور هزینه شماره {id}'
    }
  },

  countries: {
    IR: {
      name: 'ایران',
      provinces: {
        EAZ: 'آذربايجان شرقي',
        WAZ: 'آذربايجان غربي',
        ADL: 'اردبيل',
        ESF: 'اصفهان',
        ABZ: 'البرز',
        ILM: 'ايلام',
        BHR: 'بوشهر',
        THR: 'تهران',
        SKH: 'خراسان جنوبي',
        RKH: 'خراسان رضوی',
        NKH: 'خراسان شمالي',
        KHZ: 'خوزستان',
        ZJN: 'زنجان',
        SMN: 'سمنان',
        SBN: 'سيستان وبلوچستان',
        FRS: 'فارس',
        GZN: 'قزوين',
        QHM: 'قم',
        KRD: 'كردستان',
        KRN: 'كرمان',
        KRH: 'کرمانشاه',
        KBD: 'كهگيلويه وبويراحمد',
        GLS: 'گلستان',
        GIL: 'گيلان',
        LRS: 'لرستان',
        MZN: 'مازندران',
        MKZ: 'مرکزی',
        HRZ: 'هرمزگان',
        HDN: 'همدان',
        CHB: 'چهارمحال وبختياری',
        YZD: 'یزد'
      }
    },
    AE: {
      name: 'امارات',
      provinces: {
        AE_AA: 'العین',
        AE_AZ:'ابوظبی',
        AE_AJ: 'عجمان',
        AE_DU: 'دبی',
        AE_FU: 'فجیره',
        AE_RK: 'راس الخیمه',
        AE_SH: 'شارجه',
        AE_UQ: 'ام القوین'
      }
    },
    KW: {
      name: 'کویت',
      provinces: {
        KW_AA: 'عاصمه',
        KW_HA: 'حولی',
        KW_AF: 'فروانیه',
        KW_MK: 'مبارک الکبیر',
        KW_AJ: 'جهرا',
        KW_AH: 'احمدی'
      }
    },
    SA: {
      name: 'عربستان سعودی',
      provinces: {
        SA_RH: 'ریاض',
        SA_MH: 'مکه',
        SA_AM: 'مدینه',
        SA_EP: 'شرقی',
        SA_AQ: 'قصیم',
        SA_HI: 'حائل',
        SA_TK: 'تبوک',
        SA_NB: 'الحدود الشمالیه',
        SA_JN: 'جیزان',
        SA_NN: 'نجران',
        SA_AB: 'باحه',
        SA_AJ: 'جوف',
        SA_AR: 'عسیر'
      }
    },
    OM: {
      name: 'عمان',
      provinces: {
        OM_MC: 'مسقط',
        OM_AD: 'داخلیه',
        OM_BN: 'باطنه شمالی',
        OM_BS: 'باطنه جنوبی',
        OM_BU: 'بریمی',
        OM_DH: 'ظاهره',
        OM_WS: 'وسطی',
        OM_MN: 'مسندم',
        OM_SN: 'شرقیه شمالی',
        OM_SS: 'شرقیه جنوبی',
        OM_DO: 'ظفار'
      }
    },
    BH: {
      name: 'بحرین',
      provinces: {
        BH_CL: 'عاصمه',
        BH_SN: 'شمالیه',
        BH_NN: 'جنوبیه'
      }
    },
    QA: {
      name: 'قطر',
      provinces: {
        QA_AS: 'الشمال',
        QA_AK: 'الخور',
        QA_AH: 'الشحانیه',
        QA_US: 'ام صلال',
        QA_AD: 'الضعاین',
        QA_AW: 'دوحه',
        QA_AR: 'الریان',
        QA_AA: 'الوکره'
      }
    }
  }
}
